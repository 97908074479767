import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import AboutUs from './pages/AboutUs';
import Simple from './components/header/Header';
import NewsAndEvents from './pages/NewsAndEvents';
import MissionDepartment from './pages/MissionDepartment';
import LiteratureAndCommunication from './pages/LiteratureAndCommunication';
import ContactUs from './pages/ContactUs';
import BlogDetails from './pages/BlogDetails';
import DiscipleshipAndChurchDepartment from './pages/DiscipleshipAndChurchDepartment';
import TheologicalAndMinisterialEducation from './pages/TheologicalAndMinisterialEducation';
import {Box} from '@chakra-ui/react';
import LargeWithNewsletter from './components/footer_with_newsletter/FooterWithNewsletter';

function App() {
    return (
        <div className="App">
            <Router>
                <Box pos="sticky" top={0} w="100%" zIndex={5}>
                    <Simple />
                </Box>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/about_us" element={<AboutUs />} />
                    <Route
                        exact
                        path="/news_and_events"
                        element={<NewsAndEvents />}
                    />
                    <Route
                        exact
                        path="/mission_department"
                        element={<MissionDepartment />}
                    />
                    <Route
                        exact
                        path="/discipleship_and_church_education_department"
                        element={<DiscipleshipAndChurchDepartment />}
                    />
                    <Route
                        exact
                        path="/theological_and_ministerial_education"
                        element={<TheologicalAndMinisterialEducation />}
                    />
                    <Route
                        exact
                        path="/literature_and_communication"
                        element={<LiteratureAndCommunication />}
                    />
                    <Route exact path="/contact_us" element={<ContactUs />} />
                    <Route
                        exact
                        path="/blog_details/:id"
                        element={<BlogDetails />}
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Box px={{base: '10%', md: '8%'}}>
                    <LargeWithNewsletter />
                </Box>
            </Router>
        </div>
    );
}

export default App;
