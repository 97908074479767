import {
    Flex,
    Link,
    Box,
    Divider,
    Center,
    VStack,
} from '@chakra-ui/react';
import {
    FaFacebook,
    FaYoutube,
} from 'react-icons/fa';
import {MdPlace} from 'react-icons/md';
import {PhoneIcon} from '@chakra-ui/icons';
import colors from '../../constants/colors';
import {useMediaQuery} from '@chakra-ui/react';

const ContactAddress = () => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');

    return (
        <Flex
            justifyContent={'space-between'}
            direction={{base: 'column', md: 'row'}}
            gap={isMobile && 4}
        >
            <VStack className="md:mb-0 lg:mb-0 mb-4">
                <a
                    href={
                        'https://www.facebook.com/Ethiopian-Kale-Heywet-Church-Communication-1568823503183232/?epa=SEARCH_BOX'
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        background: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <FaFacebook color={colors.primary} fontSize="25px" />{' '}
                    <span style={{paddingLeft: 35}}>Facebook</span>
                </a>
                <a
                    href={
                        'https://www.youtube.com/channel/UCUa6Tchv9cDDB3OVamN9dGQ'
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        background: 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <FaYoutube fontSize="25px" color={colors.primary} />
                    <span style={{paddingLeft: 35}}>YouTube</span>
                </a>
            </VStack>
            <Center height="80px" display={{base: 'none', md: 'block'}}>
                <Divider orientation="vertical" />
            </Center>
            <Flex alignItems={'center'}>
                <PhoneIcon mr={8} fontSize="25px" color={colors.primary} />
                <Link href={'#'}>
                    011-5 15 86 93<br></br>011-5 53 49 00
                </Link>
            </Flex>
            <Center height="80px" display={{base: 'none', md: 'block'}}>
                <Divider orientation="vertical" />
            </Center>
            <Flex alignItems={'center'} textAlign="left">
                <Box mr={8}>
                    <MdPlace fontSize="30px" color={colors.primary} />
                </Box>
                <Link href={'#'}>
                    Infront of African Union<br></br>Addis Ababa Ethiopia
                </Link>
            </Flex>
        </Flex>
    );
};

export default ContactAddress;
