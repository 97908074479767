export const articlesLiterature = {
    normal: [
        {
            id: 1,
            image: {src: '/assets/literatures.png'},
            title: 'Different Publications',
            list: [
                {
                    id: 1,
                    text: `Publication and circulation of the Church's legal documents, 
                magazines, books and news papers`,
                },
                {
                    id: 2,
                    text: `Modern and  well equipped printing  press service for Church  bodies`,
                },
                {
                    id: 3,
                    text: `Organizing archive  centers  in all zones  and working with  organizations  that can  partner in the  process`,
                },
                {
                    id: 4,
                    text: `Setting into motion efficient  and  inclusive Television service`,
                },
                {
                    id: 5,
                    text: `Disseminating  Church messages through  radio programs`,
                },
                {
                    id: 6,
                    text: `Broadcasting Church  information through  website  and exerting sustainable  follow  up. – Organizing social  media  ministries (YouTube,  Facebook, Twitter`,
                },
            ],
            imagePos: 'right',
        },
    ],
};
