import {
    VStack,
    Text,
    GridItem,
    Grid,
    useBreakpointValue,
} from '@chakra-ui/react';
import AccordionToggle from '../Accordion';
import ministries from './ministries';
import {useTitleStyle} from './accordionStyle';

const Accordions = () => {
    return (
        <Grid templateColumns={useBreakpointValue({lg: '2fr 3fr'})} gap={6}>
            <GridItem my={'auto'}>
                <Text {...useTitleStyle()}>
                    What We Do Through Our Ministries
                </Text>
            </GridItem>
            <GridItem>
                <VStack>
                    {ministries.map(item => {
                        return (
                            <AccordionToggle
                                key={item.id}
                                title={item.title}
                                description={item.list}
                            />
                        );
                    })}
                </VStack>
            </GridItem>
        </Grid>
    );
};

export default Accordions;
