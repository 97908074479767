import {Box, Grid} from '@chakra-ui/react';
import ContactAddressMap from '../components/contact_address_map/ContactAddressMap';
import ContactAddress from '../components/contact_address/ContactAddress';
import FormSection from '../components/contact_form/ContactForm';
import colors from '../constants/colors';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const ContactUs = () => {
    return (
        <>
            <ScrollToTop />
            <Grid>
                <Box px={{base: '10%', md: '8%'}} py={20} mt={20}>
                    <ContactAddressMap />
                </Box>
                <Box px={{base: '10%', md: '8%'}} py={10}>
                    <ContactAddress />
                </Box>
                <Box
                    px={{base: '10%', md: '8%'}}
                    py={20}
                    mt={20}
                    color="white"
                    background={colors.primary}
                >
                    <FormSection />
                </Box>
            </Grid>
        </>
    );
};

export default ContactUs;
