export const articlesTheology = {
    normal: [
        {
            id: 1,
            title: 'HISTORICAL BACKGROUND',
            content: `The Ethiopian Kale Heywet Church (EKHC) is the largest Evangelical Church in Ethiopia born and growing up by the power of God’s word. 
                It believes that the word of God is the ultimate source of authority for all its beliefs and undertakings. We owe a huge gratitude to SIM missionaries 
                who introduced the sound teaching and played vital role in developing opportunities for non-formal and formal theological education. The fast growth the church
                observed during the Italian Invasion necessitated having formal training institutes to address the need for leadership development. This growth continued over the 
                last 96 years because EKHC has focused on theological education that helped to raise men and women with a strong biblical foundation and a sense of responsibility to
                pass on the message of the Gospel to those who do not have the access.`,
            additionalContents: [
                ` Its commitment to biblical teaching has been instrumental to sustain the unity of this church and
                    has made EKHC the home of nations and nationalities that value diversity and works towards Christ-centered unity. Currently,
                    EKHC is blessed with 11000 local churches and over ten million members engaged in providing holistic service to their communities. With this binding legacy EKHC strives
                    to make Christ known to all Ethiopians and beyond.`,
                `
                In the initial years (1927- 1935) SIM missionaries used non-formal training programs to disciple new believers and train emerging leaders. 
                Unfortunately, this ministry was brought to a halt due to the Italian invasion (1935-1941) that compelled SIM missionaries to leave. 
                Then, native believers claimed leadership of the emerging church and aggressively engaged in evangelism, church planting, and discipleship. 
                Consequently, thousands of people responded to the message of the Gospel and they planted hundreds of churches.`,

                ` When SIM missionaries came back around 1941, they found many established churches in southern, south western and western Ethiopia. With this indigenous development,
                 they observed that ministerial training was a felt need of the emerging church. Next, we will introduce how the missionaries and early leaders responded to this need.`,
            ],
        },
    ],
    subArticles: [
        {
            id: 1,
            title: '1. Beginning of Bible Schools Ministry',
            content: `Bible schools’ ministry started in 1947 to produce ministers to the emerging churches.
                Mainly, SIM missionaries played pivotal role in curriculum development, administration and teaching until 1977. 
                Since then, this role is fully assumed by Ethiopians. The early Bible schools run three programs: foundational, 
                medium and higher level certificate programs that continued until 1988. 
                Then, EKHC decided to run two programs: higher level certificate and diploma programs.
                Currently, EKHC has 162 Bible schools (148 diploma level and 14 certificate level). 
                Most of these schools use Amharic as a medium of instruction except for 7 Bible schools that use Oromipha. 
                These schools are easily accessible and using strong curriculum that facilitates the provision of solid ministerial training. 
                Over the last 75 years, EKHC’s Bible schools produced more than 75,000 ministers who have contributed a lot to the growth of the church within Ethiopia and beyond. 
                EKHC has two diploma level Bible schools in South Africa serving its mission to equip Ethiopian Christians in the Diaspora. 
                Currently, 2563 students are studying in the aforementioned Bible schools.   `,
            image: {src: '/assets/bible_school.jpg'},
            image2: {src: '/assets/school_peoples.jpg'},
            textWrap: 1100,
        },
        {
            id: 2,
            title: '2. Emergence of theological Colleges',
            content: `Grace Bible Institute (GBI) marks the first school to introduce higher level theological training. It started its service in 1966 to address the need for higher level theological training.
                While GBI was on its immature stage, the Marxist regime closed it down (in 1978), plundered its property and put the teachers in prison. 
                Fortunately, GBI left behind a handful of graduates (about 81) who nurtured and encouraged believers who were passing through persecution during the Communist regime. 
                Graduates of GBI and Amharic Bible Schools served sacrificially and faithfully during those hard times and the church flourished regardless of the suffering it endured. `,
            image: {src: '/assets/theo_graduates.jpg'},
            image2: {src: '/assets/school_peoples.jpg'},
            additionalContent: `At the eve of the down fall of the Marxist Government in Ethiopia, leaders of the Ethiopian Kale Heywet Church and SIM observed an open door when the Communist regime loosened its policy on religious education. 
                Using this opportunity, EKHC opened a number of colleges. The first attempt was actualized by opening the Evening 
                Theological Training College in Addis Ababa, which later developed into the Evangelical Theological College (ETC) in 1983. 
                Soon, more colleges were founded one after the other consecutively as the church realized its ministry needs and thought of how it could fulfill its mission better. 
                Since 1983, EKHC’s theological colleges graduated 2849 students who are serving as leaders, teachers, pastors and missionaries within Ethiopia and beyond. 
                Currently, 880 students are studying in EKHC’s colleges. The purpose of EKHC’s Theological institutes is to glorify God by producing spiritually and academically 
                equipped ministers in diversified fields of ministry for the urban and rural Kale Heywet Churches, and other evangelical churches in Ethiopia and beyond. 
                To accomplish this purpose, EKHC administers the aforementioned 162 Bible schools and 15 theological colleges (including EGST1). 
            `,
        },
        {
            id: 3,
            title: '3. Opportunities for Non-Formal Training ',
            content: `EKHC believes in the priesthood of all Christians and that it cannot address the training needs of its constituencies via formal training schemes alone. 
                So, it has provided various training opportunities to those who are interested in getting theological training but cannot afford time to join its formal institutions due to engagement in various professions. 
                Currently, EKHC’s theological education department oversees three none formal training programmes: Biblical Training for Church Pastors (BTCP), Theological Education by Extension (TEE), and Fellowship Bible Study (FBS). 
                All these initiatives attempt to facilitate exposure to fundamental theological training to every member of the EKHC in order to empower them to play active role in the mission of God through His church. 
            `,
        },
        {
            id: 4,
            title: '4. Emergence of theological Colleges',
            content: `All of these initiatives require lots of development to meet the national and international standards. Formal institutes heavily rely on the tuition fees that come from students’ sending churches.
                Faculty and facility development schemes suffer a lot due to financial constraints. EKHC extends kind request to its constituencies to strengthen your commitment to support this essential ministry. 
                Also, we invite likeminded partners to work with the EKHC in equipping the saints for the ministry of God’s Kingdom. 
                If you are interested to get further information, please, contact the following leaders. Thanks.`,
        },
    ],
    memberPeoples: [
        {
            name: 'Wondimu Abebe',
            status: 'Head of Theological Education Department',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
                'Office: 251 11 553 9992',
                'Mobile: 251 9 16 83 85 25',
                'Email:  wonselers@gmail.com',
            ],
        },
        {
            name: 'Berhanu Wolde',
            status: 'Colleges’ Ministry Coordinator',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
                'Mobile: 251 9 11 83 68 00',
                'Email:  bwolde92@gmail.com',
            ],
        },
        {
            name: 'Getahun Tadewos',
            status: 'Bible Schools’ Ministry Coordinator',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
                'Office: 251 11 553 9979',
                'Mobile: 251 9 11 31 39 35 ',
                'Email: getahuntadiwos3139@gmail.com',
            ],
        },
        {
            name: 'Tadesse Fufa',
            status: 'Bible Schools’ Ministry Assistant Coordinator',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
                'Mobile: 251 9 18 78 46 67 ',
                'Email: ayantuj2@gmail.com',
            ],
        },
        {
            name: 'Asrat Brehanu',
            status: '',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
            ],
        },
        {
            name: 'Getahun Dunalo',
            status: '',
            contacts: [
                'P.O.Box: 5829 Addis Ababa, Ethiopia',
            ],
        },
    ],
};
