import {Stack, Flex, Text, VStack, useBreakpointValue} from '@chakra-ui/react';
import {
    FlexContainer,
    useVStackStyle,
    useHeroSubtitle,
    useHeroTitle,
} from './heroStyles';

export default function WithBackgroundImage() {
    return (
        <Flex {...FlexContainer()}>
            <VStack {...useVStackStyle(useBreakpointValue)}>
                <Stack maxW={'xl'} align={'flex-start'} spacing={6}>
                    <Text {...useHeroSubtitle()}>Welcome to</Text>
                    <Text {...useHeroTitle()}>
                        The Ethiopian Kale Heywet Church
                    </Text>
                    <Text {...useHeroSubtitle()}>Work For God's Kingdom</Text>
                </Stack>
            </VStack>
        </Flex>
    );
}
