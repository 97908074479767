import {SimpleGrid, Box, Text, Spinner} from '@chakra-ui/react';
import React from 'react';
import BlogArticl from '../blog/Blog';
import {useTitleStyle, SimpleGridStyle} from './blogArticlesStyle';
import {useMediaQuery} from '@chakra-ui/react';

const ArticleList = ({
    title,
    blogs,
    type,
    setActiveBlogIndex,
    maxWidth,
    redirectBlog,
    toMainBlogSection,
}) => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');

    return (
        <Box>
            <Text {...useTitleStyle()}>{title}</Text>
            <SimpleGrid {...SimpleGridStyle(type)}>
                {blogs && blogs.length > 0 ? (
                    blogs.map(blog => {
                        return (
                            <Box
                                mb={isMobile && 10}
                                maxWidth={maxWidth}
                                key={blog.id}
                            >
                                <BlogArticl
                                    key={blog.id}
                                    title={blog.title}
                                    content={blog.content}
                                    image={blog.image}
                                    type={type}
                                    id={blog.id}
                                    setActiveBlogIndex={setActiveBlogIndex}
                                    redirectBlog={redirectBlog}
                                    toMainBlogSection={toMainBlogSection}
                                />
                            </Box>
                        );
                    })
                ) : (
                    <Box style={{width: '100%'}}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            alignSelf="center"
                        />
                    </Box>
                )}
            </SimpleGrid>
        </Box>
    );
};

export default ArticleList;
