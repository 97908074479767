import {Box} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import {articleChurchEducation} from '../components/articles/articleChurchEducation';
import {useMediaQuery} from '@chakra-ui/react';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import TheologyArticles from '../components/articles/educationDep/TheologyArticles';

const DiscipleshipAndChurchDepartment = () => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');
    const articleChurchEducationMobile = {
        normal: articleChurchEducation?.normal?.map(article => ({
            ...article,
            imagePos: 'left',
        })),
    };

    return (
        <>
            <ScrollToTop />
            <Box h={'400px'} overflowY="hidden">
                <HeroWithTitleCenter
                    title="Discipleship And Church Education Department"
                    bgUrl="url('/assets/worship.jpeg')"
                />
            </Box>
            <Box
                px={{base: '10%', md: '8%'}}
                py={{base: 20, md: 40}}
                mt={{base: 20}}
            >
                <TheologyArticles
                    articles={
                        isMobile
                            ? articleChurchEducationMobile
                            : articleChurchEducation
                    }
                    withPlain={true}
                    wrapped={!isMobile && true}
                />
            </Box>
        </>
    );
};

export default DiscipleshipAndChurchDepartment;
