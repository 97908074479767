import {Button, HStack, Input} from '@chakra-ui/react';
import colors from '../../constants/colors';

const SearchBar = ({filterText, setFilterText}) => {
    return (
        <HStack>
            <Input
                bg={colors.inputBg}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
            />
            <Button lg="true" bg={colors.primary} color={'white'} w="20%">
                Search
            </Button>
        </HStack>
    );
};

export default SearchBar;
