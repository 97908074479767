import {Box, Text, useBreakpointValue, Stack} from '@chakra-ui/react';
import {useState} from 'react';
import useTypography from '../../constants/typography';
import {useTitleStyle, WrapperStackStyle, BoxStyle} from './highlighCardStyle';
import {focusAreas} from './highlightCardsData';

const Card = ({active, setActive, item}) => {
    return (
        <Box w="100%">
            <Stack {...WrapperStackStyle(active === item.id)}>
                <Box {...BoxStyle(active === item.id)} alignItems="center">
                    <img
                        src={item.icon}
                        style={{maxWidth: '60%', maxHeight: '60%'}}
                        alt=""
                    />
                </Box>
                <Text {...useTypography(useBreakpointValue).cardTitleMd}>
                    {item.title}
                </Text>
                <Text {...useTypography(useBreakpointValue).cardDescription}>
                    {item.description}
                </Text>
            </Stack>
        </Box>
    );
};

const HighlightCard = () => {
    const [active, setActive] = useState(null);
    return (
        <Stack>
            <Text {...useTitleStyle()}>Ministry Focus Areas</Text>
            <div className="grid lg:grid-cols-3 md:gird-cols-3 gap-12">
                {focusAreas.map(item => {
                    return (
                        <Card
                            key={item.id}
                            active={active}
                            setActive={setActive}
                            item={item}
                        />
                    );
                })}
            </div>
        </Stack>
    );
};

export default HighlightCard;
