import {
    Box,
    Text,
    useBreakpointValue,
    Stack,
    Flex,
    SimpleGrid,
    Divider,
} from '@chakra-ui/react';
import useTypography from '../../constants/typography';
import {SimpleGridContainer, StackStyle, BoxStyle} from './cardStackStyle';
import {useMediaQuery} from '@chakra-ui/react';

const stats = [
    {
        id: 1,
        icon: '/assets/user-groups.svg',
        number: '10 Million+',
        text: 'Church Members',
    },
    {
        id: 2,
        icon: '/assets/user_star.svg',
        number: '1,500+',
        text: 'Global and Local Missionaries',
    },
    {
        id: 3,
        icon: '/assets/church_blue.svg',
        number: '12,000+',
        text: 'Local Churches',
    },
    {
        id: 4,
        icon: '/assets/graduate.svg',
        number: '154+',
        text: 'Bible Schools',
    },
];

const Card = ({item, noDivider}) => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');

    return (
        <Flex py={10} direction={isMobile && 'column'}>
            <Stack key={item.id} {...StackStyle}>
                <Box {...BoxStyle}>
                    <img
                        src={item.icon}
                        alt=""
                        style={{
                            justifyContent: 'center',
                            alignSelf: 'center',
                            alignItems: 'center',
                        }}
                    />
                </Box>
                <Text fontWeight={800} fontSize={{md: 30, lg: 36}}>
                    {item.number}
                </Text>
                <Text {...useTypography(useBreakpointValue).cardTitleMd}>
                    {item.text}
                </Text>
            </Stack>
            {!noDivider && !isMobile && <Divider orientation="vertical" />}
            {!noDivider && isMobile && <Divider orientation="horizontal" />}
        </Flex>
    );
};

const CardStack = () => {
    return (
        <SimpleGrid {...SimpleGridContainer}>
            {stats.map((item, index) => {
                return (
                    <Card
                        key={item.id}
                        item={item}
                        noDivider={index >= stats.length - 1}
                    />
                );
            })}
        </SimpleGrid>
    );
};

export default CardStack;
