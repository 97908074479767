const articles = {
    normal: [
        {
            id: 1,
            image: {src: '/assets/ekhc_history.png'},
            title: 'EKHC History',
            content: `The Ethiopian Kale Heywet Church was established in 1920 by SIM Missionaries who came from North
            America. The church, from its very beginning, was engaged in holistic ministry that included evangelism,
            church planting, literacy campaign, and providing health care. All these were done despite acute
            sufferings. During that period, the church was mainly active in the Southwestern areas of Ethiopia. Later,
            these activities were terminated by the invasion of the Italians.
            When the America Missionaries were expelled, the first believers carried on the ministries evangelism,
            church planting, literacy campaign and health care by enduring heavy persecution. As a result, between
            the years 1940-1966, the church widened its horizon and the number of its members and local churches
            continued to grow.\n
            During the Communist Military (Dergue) regime the church went through another round of persecution.
            Thousand of churches were closed and hundreds of schools and other development facilities were
            confiscated by the government. Even during those years, the church continued to grow underground. By
            God’s grace the difficult years passed, and the country began to exercise religious freedom after the fall
            of the Dergue Regime in 1991. EKHC used this freedom to the maximum and became one of the
            largest Evangelical denominations in Ethiopia. The Church currently has over 11,000 local Churches with
            a membership of more than 10 million.\n
            The church started engaging in development work in a well-organized way in 1985 (during the
            communist regime). This was envisioned by the church leaders to tackle two problems. The first one is
            to protect itself from the governments hostile attacks by showing that its relief work is helping those
            who were living in areas that were hit by severe famine and its development work is helping those who
            are in need. The other reason was to show compassion to people by addressing their physical needs as
            well as sharing the gospel message with them. Since then, millions have benefited from the
            development works of EKHC through development programs such as Water and Health, Education,
            Children support, Integrated Rural Development, organized Self Help Groups etc.
            The spiritual ministry programs also continued to expand in evangelism, church planning, national and
            overseas mission, children and youth ministry and training of ministers. The church now one college that
            offers undergraduate and graduate programs and another graduate school (that EKHC shares ownership
            with other denominations) that offer graduate programs up to PhD. In addition to that EKHC has 14
            theological colleges and 162 Bible schools that train its ministers. Currently, the church has over
            2000 cross cultural missionaries within the country and about 30 missionary families serving as
            international missionaries.`,
            imagePos: 'right',
            textWrap:850,
        },
        {
            id: 2,
            image: {src: '/assets/ekhc_about_us.png'},
            title: 'More About Us',
            content: `The Church has gone through several cylces of persecution from the government and people of other 
            religions. However, those circumstances didn't deter the Church from reaching out to more people and 
            growing. Currently, the EKHC is functioning in all regions of the country with a structure of 13 
            zonal administrations, 115 district offices, and thousands of local churches in the country. It 
            has also 9 special centers that provide a wide range of spiritual and social services. The Church 
            trains ministers in 2 schools that offer graduate programs, 14 Theological colleges, 157 Bible 
            schools, and thousands of other academic schools to serve our ministers and the whole nation at 
            large. The Church has also engaged in various charity, and Christian love ministries by supporting 
            elderly people, widows, orphans, and other sections of the society that need support in a stewardship 
            mentality. By doing so, it is contributing its part to the holistic development of the nation. 
            The EKHC is still commited to winning souls for Christ and building the body of Christ by 
            edifying believers to grow into the likeness of Jesus and contributing to the transormational 
            development of people in Ethiopia and beyond.`,
            imagePos: 'left',
            textWrap:800,
        },
    ],
    plain: [
        {
            id: 3,
            image: {src: ''},
            title: 'Our Vision',
            content: `EKHC's vision is to double the number of its members (to 20 million) and 
            local Churches (to 20,000) by 2027 and to see its members become commited followers 
            of Jesus Christ that bring Godly influence in their societies.`,
        },
        {
            id: 4,
            image: {src: ''},
            title: 'Our Mission',
            content: `Ths Mission of EKHC is glorifying God by proclaiming the gospel of Jesus 
            Christ to the people of Ethiopia and beyond, bringing those who believe into the 
            fellowship of the Church, making them desciples of Jesus Christ and
            Equipping them to be actively involved in the Church's holistic ministry`,
        },
        {
            id: 3,
            image: {src: ''},
            title: 'Core Values',
            content: `EKHC's Core Values are Supremacy of God's word, Love, Leading of the Holy 
            Spirit, Prayer, Stewardship, Servant Leadership and Holistic Ministry`,
        },
        {
            id: 4,
            image: {src: ''},
            title: 'Focus Areas',
            content: `EKHC's Strategic Focus Areas are Evangelism and Church planting, Dscipling 
            believers, Strengthening childrent and youth ministry, Equipping effective ministries,
            Increasing participation of women in ministry and Building financial sustainability`,
        },
    ],
};

export default articles;
