import React, {useEffect} from 'react';

const ScrollToTop = () => {
    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return <span></span>;
};

export default ScrollToTop;
