import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    useColorModeValue,
    Image,
    Flex,
    Button,
} from '@chakra-ui/react';
import {FaFacebook} from 'react-icons/fa';
import {BiMailSend} from 'react-icons/bi';
import {MdPlace} from 'react-icons/md';
import {PhoneIcon} from '@chakra-ui/icons';
import colors from '../../constants/colors';
import {useState} from 'react';
import {db} from '../../firebase/firebaseConfig';
import {addDoc, collection} from 'firebase/firestore';
import {getFormattedCurrentDate} from '../../util/utils';
import {subscriptionTemplate} from '../email_template/SubscriptionTemplate';
import {enqueueSnackbar} from 'notistack';
import {Link} from 'react-router-dom';

const SocialButton = ({children, label, href}) => {
    return (
        <chakra.button
            w={8}
            h={8}
            cursor={'pointer'}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const ListHeader = ({children}) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function LargeWithNewsletter() {
    const [subscriberEmail, setSubscriberEmail] = useState('');
    const subscribersCollectionRef = collection(db, 'subscribers');
    const mailCollectionRef = collection(db, 'mail');

    const submitSubscriberEmail = async e => {
        e.preventDefault();
        try {
            const createdAt = getFormattedCurrentDate();

            await addDoc(subscribersCollectionRef, {
                email: subscriberEmail,
                createdAt: createdAt,
            });

            await addDoc(mailCollectionRef, {
                to: ['ekhc.net@gmail.com'],
                message: {
                    subject: 'New Subscription Notification',
                    html: subscriptionTemplate(subscriberEmail),
                },
            });

            setSubscriberEmail('');
            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Box color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'100%'} py={10} textAlign="left">
                <SimpleGrid
                    templateColumns={{
                        sm: '1fr 1fr',
                        md: '2fr 2fr',
                        lg: '4fr 3fr 5fr 6fr',
                    }}
                    spacing={8}
                    borderBottom={`solid 5px ${colors.primary}`}
                    mb={8}
                    pb={10}
                >
                    <Stack spacing={6}>
                        <Box>
                            <Image
                                src="/assets/footer_logo.png"
                                w="100px"
                                h="54px"
                            />
                        </Box>
                        <Text fontSize={'sm'} textAlign="left">
                            Follow us
                        </Text>
                        <Stack
                            direction={'row'}
                            spacing={6}
                            color={colors.primary}
                        >
                            <a
                                href={
                                    'https://www.facebook.com/Ethiopian-Kale-Heywet-Church-Communication-1568823503183232/?epa=SEARCH_BOX'
                                }
                                target="_blank"
                                rel="noreferrer"
                                style={{background: 'none'}}
                            >
                                <SocialButton label={'Facebook'}>
                                    <FaFacebook fontSize="20px" />
                                </SocialButton>
                            </a>
                        </Stack>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{''}</ListHeader>
                        <Link to={'home'}>Home</Link>
                        <Link to={'about_us'}>About us</Link>
                        <Link to={'news_and_events'}>News and Events</Link>
                        <Link to={'contact_us'}>Contact Us</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>{''}</ListHeader>
                        <Flex alignItems={'center'}>
                            <PhoneIcon
                                mr={8}
                                fontSize="25px"
                                color={colors.primary}
                            />
                            <Link to={'#'}>
                                011-5 15 86 93<br></br>011-5 53 49 00
                            </Link>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <Box mr={8}>
                                <MdPlace
                                    fontSize="30px"
                                    color={colors.primary}
                                />
                            </Box>
                            <Link to={'#'}>
                                Infront of African Union<br></br>Addis Ababa
                                Ethiopia
                            </Link>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <Box mr={8}>
                                <BiMailSend
                                    fontSize="30px"
                                    color={colors.primary}
                                />
                            </Box>
                            <Link to={'#'}>contactus@ekhc.net</Link>
                        </Flex>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader fontWeight={800}>
                            Join Our NewsLetter
                        </ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={'Your email address'}
                                value={subscriberEmail}
                                required
                                bg={useColorModeValue(
                                    'blackAlpha.100',
                                    'whiteAlpha.100'
                                )}
                                border={0}
                                type="email"
                                onChange={e =>
                                    setSubscriberEmail(e.target.value)
                                }
                                _focus={{
                                    bg: 'whiteAlpha.300',
                                }}
                            />
                            <Button
                                bg={colors.primary}
                                px={8}
                                color="white"
                                onClick={submitSubscriberEmail}
                            >
                                Subscribe
                            </Button>
                        </Stack>
                        <Text textAlign={'left'} fontStyle="italic" w="250px">
                            Subscribe to get frequent updates.
                        </Text>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
