import {Text, Image, GridItem, Grid} from '@chakra-ui/react';
import {
    useArticleTitleStyle,
    useArticleWIthImageContainer,
} from './contactAddressStyle';

const ContactAddressMap = () => {
    return (
        <Grid {...useArticleWIthImageContainer()}>
            <GridItem>
                <Text {...useArticleTitleStyle()}>Get in touch with us</Text>
            </GridItem>
            <Grid>
                <Image src="/assets/contact_us_map.png" />
            </Grid>
        </Grid>
    );
};

export default ContactAddressMap;
