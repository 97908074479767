const menu = [
    {
        id: 1,
        title: 'EKHC Histroy',
        subtitle: '',
        content: `The Ethiopian Kale Heywet Church was established in 1920 by SIM Missionaries who came from North
        America. The church, from its very beginning, was engaged in holistic ministry that included evangelism,
        church planting, literacy campaign, and providing health care. All these were done despite acute
        sufferings. During that period, the church was mainly active in the Southwestern areas of Ethiopia. Later,
        these activities were terminated by the invasion of the Italians.
        When the America Missionaries were expelled, the first believers carried on the ministries evangelism,
        church planting, literacy campaign and health care by enduring heavy persecution. As a result, between
        the years 1940-1966, the church widened its horizon and the number of its members and local churches
        continued to grow.`,
        image: '/assets/bible.png',
        large: true,
    },
    {
        id: 2,
        title: 'Mission',
        content: '',
        subtitle: `Ths Mission of EKHC is glorifying God by`,
        list: [
            {
                id: 1,
                text: `proclaiming the gospel of Jesus Christ to the people of Ethiopia and beyond`,
            },
            {
                id: 2,
                text: 'bringing those who believe into the fellowship of the Church',
            },
            {id: 3, text: 'making them desciples of Jesus Christ and'},
            {
                id: 4,
                text: `Equipping them to be actively involved in the Church's holistic ministry`,
            },
        ],
        image: '/assets/bible.png',
    },
    {
        id: 3,
        title: 'Vision',
        subtitle: 'The Centennial vision of EKHC',
        content: `EKHC's vision is to double the number of its members (to 20 million) and local Churches 
        (to 20,000) by 2027 and to see its members become commited followers of Jesus Christ 
        that bring Godly influence in their societies.`,
        image: '/assets/bible.png',
    },
    {
        id: 4,
        title: 'Core Values',
        content: '',
        subtitle: `EKHC's Core Values`,
        list: [
            {id: 1, text: `Supremacy of God's word`},
            {id: 2, text: 'Love'},
            {id: 3, text: 'Leading of the Holy Spirit'},
            {id: 4, text: 'Prayer'},
            {id: 5, text: 'Stewardship'},
            {id: 6, text: 'Servant Leadership'},
            {id: 7, text: 'Holistic Ministry'},
        ],
        image: '/assets/bible.png',
    },
    {
        id: 5,
        title: 'Focus Areas',
        subtitle: `EKHC's Strategic Focus Areas`,
        content: '',
        list: [
            {id: 1, text: 'Evangelism and Church planting'},
            {id: 2, text: 'Discipling believers'},
            {id: 3, text: 'Strengthening children and youth ministry'},
            {id: 4, text: 'Equipping effective ministries'},
            {id: 5, text: 'Increasing participation of women in ministry'},
            {id: 6, text: 'Building financial sustainability'},
        ],
        image: '/assets/bible.png',
    },
];

export default menu;
