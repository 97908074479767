import {Grid, Box} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import Articles from '../components/articles/Articles';
import articlesMission, {
    articlesStrategy,
} from '../components/articles/articlesMission';
import PhotoSeries from '../components/photo/PhotoSeries';
import {useMediaQuery} from '@chakra-ui/react';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const MissionDepartment = () => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');
    const articlesMissionMobile = {
        normal: articlesMission.normal.map(article => ({
            ...article,
            imagePos: 'left',
        })),
    };
    return (
        <Grid>
            <ScrollToTop />
            <Box h={'400px'} overflowY="hidden">
                <HeroWithTitleCenter
                    title="Mission Department"
                    bgUrl="url('/assets/worship.jpeg')"
                />
            </Box>
            <Box px={{base: '10%', md: '8%'}} pt={60} pb={{base: 20, md: 40}}>
                <Articles
                    articles={
                        isMobile ? articlesMissionMobile : articlesMission
                    }
                    wrapped={!isMobile && true}
                />
            </Box>
            <Box px={{base: '10%', md: '8%'}} pb={{base: 40, md: 60}}>
                <PhotoSeries />
            </Box>
            <Box px={{base: '10%', md: '8%'}} py={20}>
                <Articles
                    articles={articlesStrategy}
                    wrapped={!isMobile && true}
                />
            </Box>
        </Grid>
    );
};

export default MissionDepartment;
