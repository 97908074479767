import useTypography from '../../constants/typography';
import colors from '../../constants/colors';

export const useLinkStyle = (useColorModeValue, useBreakpointValue) => ({
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 1,
    paddingBottom: 1,
    rounded: 'md',
    _hover: {
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
    },
    color: colors.primaryLight,
    ...useTypography(useBreakpointValue).mainNavItems,
});

export const useBoxContainer = (useColorModeValue, useBreakpointValue) => ({
    bg: useColorModeValue('gray.100', 'gray.900'),
    px: useBreakpointValue({base: 4, md: 12, lg: 32}),
});

export const FlexWrapper = {
    h: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const IconButtonStyle = {
    size: 'md',
    'aria-label': 'Open Menu',
    display: {md: 'none'},
};

export const LinksStackContainer = {
    w: '100%',
    spacing: 8,
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const LinksStackWrapper = {
    as: 'nav',
    spacing: 4,
    display: {base: 'none', md: 'flex'},
};

export const MobileBoxContainer = {
    pb: 4,
    display: {md: 'none'},
};

export const MobileLinksStackWrapper = {
    as: 'nav',
    spacing: 4,
    w: '100vw',
};
