export const articleChurchEducation = {
    normal: [
        {
            id: 1,
            title: 'Three Secessions',
            listContents: [
                'Pastors Ministry Training Network (PMN)',
                'Disciples Making Leaders and Pastors Training (DMLP)',
                'Discipling Marriage, family and Prayer ministry ',
            ],
        },
        {
            id: 2,
            title: 'Discipleship Training Models',
            listContents: [
                'Teaching truth',
                'Open door to serve',
                'Accountability',
                'Disciplined Daily Devotion',
                'Mentoring',
                'Small group or one on one',
                'Supplication',
                'Multiplication',
                'Follow up and Evaluation ',
            ],
        },
        {
            id: 3,
            title: 'Purpose',
            content:
                'EKHC- Discipleship Department purpose is to equip pastors and leaders, and believers in biblical knowledge, godly character and ministry skills so that pastors and leaders be transforming agents of Christ for effective holistic growth and maturity for God’s kingdom sake.',
        },
        {
            id: 4,
            title: 'Focus Areas',
            content:
                'Discipleship and church education Department is focusing to provide different training tools/Curriculum for Discipleship, Leadership and pastors training and consistently giving TOT’s for leaders and pastors.',
        },
    ],
};
