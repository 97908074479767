import {useBreakpointValue} from '@chakra-ui/react';
import colors from '../../constants/colors';
import useTypography from '../../constants/typography';

export const useTitleStyle = () => ({
    mb: 20,
    color: colors.primary,
    ...useTypography(useBreakpointValue).sectionTitle,
});

export const SimpleGridStyle = (type = 'column') => ({
    minChildWidth:
        type === 'row'
            ? {base: '300px', md: '500px'}
            : {base: '200px', md: '300px'},
    spacing: {md: '40px'},
});
