export const subscriptionTemplate = email => `
    <h1 style="background-color: #007bff; height: 100px; text-align: center; line-height: 100px; color: #fff;">Newsletter Subscription</h1>
    <div style="display:flex;flex-direction:row;justify-content:center;align-items:center">
    	<p style="text-left: center; margin-top: 20px; margin-bottom: 20px; color: #333333; padding-left:30px;padding-right:30px;">
	      Hello,<br><br>
	      ${email} has subscribed to your Newsletter.<br><br>
	      
  	    </p>
    </div>
`;
