import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    ListItem,
    UnorderedList,
    useBreakpointValue,
} from '@chakra-ui/react';
import useTypography from '../constants/typography';

const AccordionToggle = ({title, description}) => {
    return (
        <Accordion allowToggle w={'100%'} bg={'#ebf1ff'} p={4}>
            <AccordionItem>
                <h2>
                    <AccordionButton border={'none'}>
                        <Box
                            flex="1"
                            textAlign="left"
                            {...useTypography(useBreakpointValue)
                                .cardDescription}
                        >
                            {title}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <UnorderedList textAlign={'left'}>
                        {description.map(item => {
                            return (
                                <ListItem key={item.id}>{item.text}</ListItem>
                            );
                        })}
                    </UnorderedList>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionToggle;
