import {useBreakpointValue} from '@chakra-ui/react';
import colors from '../../constants/colors';
import useTypography from '../../constants/typography';

export const useTitleStyle = () => ({
    mb: 20,
    color: colors.primary,
    ...useTypography(useBreakpointValue).sectionTitle,
});

export const WrapperStackStyle = bool => ({
    alignItems: 'center',
    w: '100%',
    h: 340,
    px: 10,
    py: 8,
    bg: bool ? colors.primary : colors.blueWhite,
    borderRadius: 30,
    color: bool && 'white',
});

export const BoxStyle = bool => ({
    w: '70px',
    h: '70px',
    bg: bool ? 'white' : colors.primary,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
});
