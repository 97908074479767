import {
    Button,
    VStack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import colors from '../../constants/colors';
import useTypography from '../../constants/typography';
import {Link} from 'react-router-dom';
const LinkBar = () => {
    return (
        <VStack bg={colors.primary} pt="100px" pb="50px">
            <VStack color={'white'} mb={6}>
                <Text {...useTypography(useBreakpointValue).cardPersonName}>
                    Let us know in what way you can be part of the good work
                    going on
                </Text>
                <Text {...useTypography(useBreakpointValue).cardTitle}>
                    Do you want to contribute for God's Kingdom?
                </Text>
            </VStack>
            <Link to="/contact_us" textDecoration={'none'}>
                <Button
                    p={'30px 50px'}
                    color={colors.primary}
                    borderRadius="unset"
                >
                    Contact Us
                </Button>
            </Link>
        </VStack>
    );
};

export default LinkBar;
