import {Grid, Box, Spinner} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import ArticleList from '../components/blog_articles/BlogArticles';
import {
    ArticleWithImage,
    ArticleWithImageWrapped,
} from '../components/articles/Articles';
import SearchBar from '../components/search_bar/SearchBar';
import {useState} from 'react';
import {useMediaQuery} from '@chakra-ui/react';
import {useEffect} from 'react';
import {db} from '../firebase/firebaseConfig';
import {collection, getDocs} from 'firebase/firestore';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const NewsAndEvents = () => {
    const [activeBlogIndex, setActiveBlogIndex] = useState();
    const [currentBlog, setCurrentBlog] = useState();
    const [isMobile] = useMediaQuery('(max-width: 425px)');
    const [filterText, setFilterText] = useState('');
    const [blogsNews, setBlogsNews] = useState([]);
    const blogsCollectionRef = collection(db, 'blogs');

    const filteredBlogsNews = blogsNews.filter(
        item =>
            item?.title?.toLowerCase().indexOf(filterText.toLowerCase()) !==
                -1 ||
            item?.content?.toLowerCase().indexOf(filterText.toLowerCase()) !==
                -1
    );

    useEffect(() => {
        const [currentBlog] = filteredBlogsNews.filter(
            blog => blog.id === activeBlogIndex
        );
        setCurrentBlog(currentBlog);
    }, [activeBlogIndex, filteredBlogsNews]);

    useEffect(() => {
        window.scrollTo({top: 380, left: 0, behavior: 'smooth'});
    }, [activeBlogIndex]);

    useEffect(() => {
        const getBlogs = async () => {
            const data = await getDocs(blogsCollectionRef);
            setBlogsNews(
                data.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    image: {src: doc.data().image},
                }))
            );
        };

        getBlogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    blogsNews.sort((a, b) => b.createdAt - a.createdAt);

    return (
        <Grid>
            <ScrollToTop />
            <Box h={'400px'} overflowY="hidden">
                <HeroWithTitleCenter
                    title="News & Events"
                    bgUrl="url('/assets/worship.jpeg')"
                />
            </Box>
            <Box px={{base: '10%', md: '8%'}} pt={40} id="mainSectionBlog">
                {currentBlog || blogsNews.length > 0 ? (
                    isMobile ? (
                        <ArticleWithImage
                            article={currentBlog || blogsNews[0]}
                            index={0}
                        />
                    ) : (
                        <ArticleWithImageWrapped
                            article={currentBlog || blogsNews[0]}
                            index={0}
                        />
                    )
                ) : (
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                )}
            </Box>
            <Box px={{base: '10%', md: '8%'}} mt={40}>
                <Box mx="auto" w={{base: '100%', md: '60%'}}>
                    <SearchBar
                        filterText={filterText}
                        setFilterText={setFilterText}
                    />
                </Box>
            </Box>
            {filteredBlogsNews && filteredBlogsNews.length > 0 && (
                <Box px={{base: '10%', md: '8%'}} mb={40}>
                    <ArticleList
                        blogs={filteredBlogsNews}
                        setActiveBlogIndex={id => setActiveBlogIndex(id)}
                        maxWidth={'320px'}
                        toMainBlogSection="mainSectionBlog"
                    />
                </Box>
            )}
        </Grid>
    );
};

export default NewsAndEvents;
