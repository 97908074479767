import {
    useDisclosure,
    MenuItem,
    Menu,
    MenuButton,
    MenuList,
    useColorModeValue,
} from '@chakra-ui/react';

export default function OnHoverMenu({children, menuItems}) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Menu isOpen={isOpen}>
            <MenuButton
                variant="ghost"
                mx={1}
                py={[1, 2, 2]}
                px={4}
                borderRadius={5}
                _hover={{bg: useColorModeValue('gray.100', 'gray.700')}}
                aria-label="Courses"
                fontWeight="normal"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
            >
                {children}
            </MenuButton>
            <MenuList onMouseEnter={onOpen} onMouseLeave={onClose}>
                {menuItems &&
                    menuItems.map(item => {
                        return (
                            <MenuItem key={item.id} onClick={onClose}>
                                {item.Comp}
                            </MenuItem>
                        );
                    })}
            </MenuList>
        </Menu>
    );
}
