export const focusAreas = [
    {
        id: 1,
        title: 'Planting Churches',
        icon: '/assets/church.svg',
        description: 'Through evangelism both at national and global level',
    },
    {
        id: 2,
        title: 'Producing Disciples',
        icon: '/assets/priest.svg',
        description:
            "That worship Christ through preached God's word, teaching and transform their life",
    },
    {
        id: 3,
        title: 'Effective Ministers',
        icon: '/assets/correct.svg',
        description: 'Using formal and informal education programs',
    },
    {
        id: 4,
        title: 'Organizing Ministries',
        icon: '/assets/organization.svg',
        description:
            'That need special concern and providing support to Church services',
    },
    {
        id: 5,
        title: 'Providing Support',
        icon: '/assets/support.svg',
        description: 'Which enables to run Church ministries to full scale',
    },
    {
        id: 6,
        title: 'Building Centers',
        icon: '/assets/center.svg',
        description: 'Organizing them to serve Church ministries.',
    },
];
