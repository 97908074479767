import {
    VStack,
    Box,
    Text,
    Flex,
    GridItem,
    Grid,
    Image,
    UnorderedList,
    ListItem,
    useBreakpointValue,
    OrderedList,
} from '@chakra-ui/react';
import {useState} from 'react';
import {
    VStackStyle,
    useArticleTitleStyle,
    useArticleContentStyle,
    ImageWrapperStyle,
    TopLeftDecorBoxStyle,
    BottomRightDecorBoxStyle,
    ArticleImageStyle,
    TopRightDecorBoxStyle,
    BottomLeftDecorBoxStyle,
    useArticleWIthImageContainer,
    usePlainArticleContainer,
    PlainArticleArgs,
} from './theologyArticlesStyle';
import colors from '../../../constants/colors';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import useTypography from '../../../constants/typography';

const Article = ({title, content, textWrap, listContents, args}) => {
    const [expand, setExpand] = useState(false);

    return (
        <VStack {...VStackStyle(args)}>
            <Text {...useArticleTitleStyle(args && args.color)}>{title}</Text>
            <Text {...useArticleContentStyle(args && args.color)}>
                {!expand
                    ? textWrap
                        ? `${content?.substring(0, textWrap)}...`
                        : content
                    : content}
            </Text>

            <OrderedList
                listStyleType={Number}
                textAlign="left"
                style={{paddingLeft: 20}}
                {...useArticleContentStyle(args && args.color)}
            >
                {(!expand || textWrap) &&
                    listContents?.map(list => (
                        <ListItem key={list}>{list}</ListItem>
                    ))}
            </OrderedList>

            <Box color={colors.primary} fontSize="20px">
                {textWrap && (
                    <Link href="#" onClick={() => setExpand(!expand)}>
                        {expand ? 'Read less' : 'Read more'}
                    </Link>
                )}
            </Box>
        </VStack>
    );
};

const ImageWrapper = ({image, type, float}) => {
    return (
        <GridItem {...ImageWrapperStyle} float={float}>
            {type === 'top-left' ? (
                <Box {...TopLeftDecorBoxStyle}></Box>
            ) : (
                <Box {...TopRightDecorBoxStyle}></Box>
            )}
            <Image src={image?.src} {...ArticleImageStyle} />
            {type === 'top-left' ? (
                <Box {...BottomRightDecorBoxStyle}></Box>
            ) : (
                <Box {...BottomLeftDecorBoxStyle}></Box>
            )}
        </GridItem>
    );
};

const ImageWrapperWrapped = ({image, type}) => {
    return (
        <Box {...ImageWrapperStyle} w="100%" h="fit-content">
            {type === 'top-left' ? (
                <Box {...TopLeftDecorBoxStyle}></Box>
            ) : (
                <Box {...TopRightDecorBoxStyle}></Box>
            )}
            <Image src={image?.src} {...ArticleImageStyle} w="100%" />
            {type === 'top-left' ? (
                <Box {...BottomRightDecorBoxStyle}></Box>
            ) : (
                <Box {...BottomLeftDecorBoxStyle}></Box>
            )}
        </Box>
    );
};

const Info = ({info}) => {
    return (
        <Flex mt={20} alignItems="center" justifyContent={'space-around'}>
            <VStack>
                <Text>{info.comments} comments</Text>
                <Text>{info.date}</Text>
            </VStack>
            <Link href={info.path}>Read more</Link>
        </Flex>
    );
};

export const ArticleWithImage = ({article, index}) => {
    return (
        <Grid>
            {article?.imagePos === 'left' && article?.image ? (
                <ImageWrapper image={article?.image} type="top-left" />
            ) : null}
            <GridItem>
                <Article
                    title={article?.title}
                    content={article?.content}
                    textWrap={article?.textWrap}
                    listContents={article?.listContents}
                />
                {article?.info && <Info info={article?.info} />}
            </GridItem>
            {article?.imagePos === 'right' && article?.image ? (
                <ImageWrapper image={article?.image} />
            ) : null}
        </Grid>
    );
};

export const ArticleWithImageWrapped = ({article, index}) => {
    const args = null;
    const [expand, setExpand] = useState(false);

    useEffect(() => {}, [expand]);
    return (
        <Box h="fit-content">
            <Box
                w={article.image && '45%'}
                display={!article?.image && 'none'}
                h="fit-content"
                float={article?.imagePos === 'left' ? 'left' : 'right'}
                marginLeft={20}
                marginBottom={20}
            >
                {article?.image && (
                    <ImageWrapperWrapped
                        image={article?.image}
                        type="top-left"
                    />
                )}
                <Text
                    {...useTypography(useBreakpointValue).cardPersonName}
                    textAlign="left"
                    marginTop="20px"
                >
                    {article?.imageName && article.imageName}
                </Text>
                <Text
                    {...useTypography(useBreakpointValue).cardDescription}
                    textAlign="left"
                >
                    {article?.imageTitle && article.imageTitle}
                </Text>
            </Box>

            <Text
                {...useArticleTitleStyle(args && args.color)}
                textAlign="left"
            >
                {article?.title}
            </Text>

            <Text
                {...useArticleContentStyle(args && args.color)}
                textAlign="justify"
                w="auto"
                maxWidth={{base: '100%', md: '80%'}}
            >
                {!expand
                    ? article?.textWrap
                        ? `${article?.content?.substring(
                              0,
                              article?.textWrap
                          )}...`
                        : article?.content
                    : article?.content}
            </Text>

            <OrderedList
                listStyleType={Number}
                textAlign="left"
                style={{paddingLeft: 20}}
                {...useArticleContentStyle(args && args.color)}
            >
                {(!expand || article.textWrap) &&
                    article?.listContents?.map(list => (
                        <ListItem key={list}>{list}</ListItem>
                    ))}
            </OrderedList>

            <Text
                {...useArticleContentStyle(args && args.color)}
                textAlign="justify"
                w="auto"
                maxWidth={{base: '100%', md: '80%'}}
            >
                {(expand || !article.textWrap) &&
                    article?.additionalContents?.map(item => (
                        <>
                            <br />
                            {item}
                            <br />
                        </>
                    ))}
            </Text>

            <Box color={colors.primary} fontSize="20px" textAlign={'left'}>
                {article?.textWrap && (
                    <Link href="#" onClick={() => setExpand(!expand)}>
                        {expand ? 'Read less' : 'Read more'}
                    </Link>
                )}
            </Box>
            {article?.info && <Info info={article?.info} />}
        </Box>
    );
};

const ListItemComp = ({item, args}) => {
    return (
        <ListItem
            key={item?.id}
            textAlign="left"
            mb={4}
            {...useArticleContentStyle(args && args.color)}
        >
            {item?.text}
        </ListItem>
    );
};
export const ListComponent = ({list, args}) => {
    return (
        <UnorderedList>
            {list &&
                list.map(item => {
                    return <ListItemComp item={item} key={item.id} />;
                })}
        </UnorderedList>
    );
};

export const ArticleWithListAndImage = ({article, args}) => {
    return (
        <VStack alignItems={'flex-start'}>
            <Box textAlign={'left'} mb={8}>
                <Text {...useArticleTitleStyle(args && args.color)}>
                    {article?.title}
                </Text>
            </Box>
            <Grid {...useArticleWIthImageContainer()}>
                {article?.imagePos === 'left' && (
                    <ImageWrapper image={article?.image} type="top-left" />
                )}
                <GridItem>
                    <ListComponent list={article?.list} />
                </GridItem>
                {article?.imagePos === 'right' && (
                    <ImageWrapper image={article?.image} />
                )}
            </Grid>
        </VStack>
    );
};

const PlainArticles = ({children}) => {
    return <Grid {...usePlainArticleContainer()}>{children}</Grid>;
};

const TheologyArticles = ({articles, wrapped}) => {
    return (
        <Box display={'flex'} flexDir="column">
            {articles?.normal &&
                articles?.normal?.map((article, index) => {
                    return (
                        <Box mb={40} key={article?.id}>
                            {wrapped ? (
                                <ArticleWithImageWrapped
                                    article={article && article}
                                    index={index && index}
                                />
                            ) : (
                                <ArticleWithImage
                                    article={article && article}
                                    index={index && index}
                                />
                            )}
                        </Box>
                    );
                })}
            {articles?.subArticles &&
                articles?.subArticles?.map((article, index) => {
                    return (
                        <Box mb={40} key={article?.id}>
                            {wrapped ? (
                                <ArticleWithImageWrapped
                                    article={article && article}
                                    index={index && index}
                                />
                            ) : (
                                <ArticleWithImage
                                    article={article && article}
                                    index={index && index}
                                />
                            )}
                        </Box>
                    );
                })}
            <Box mt={20} display={articles?.plain ? 'block' : 'none'}>
                <PlainArticles>
                    {articles &&
                        articles.plain &&
                        articles.plain.map(article => {
                            return (
                                <GridItem
                                    bg={colors.primary}
                                    key={article?.id}
                                    mb={12}
                                >
                                    <Article
                                        title={article?.title}
                                        content={article?.content}
                                        textWrap={article?.textWrap}
                                        {...PlainArticleArgs}
                                    />
                                </GridItem>
                            );
                        })}
                </PlainArticles>
            </Box>
        </Box>
    );
};

export default TheologyArticles;
