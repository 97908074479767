export const persons = [
    {
        id: 1,
        // name: "Semeon Mulatu (PhD)",
        name: 'Tassew Gebre',
        status: 'President of EKHC',
        // bgImageUrl: "/assets/leader3.png",
        bgImageUrl: '/assets/leader2.png',
    },
    {
        id: 2,
        name: 'Semeon Heliso (PhD)',
        status: 'Vice President of EKHC',
        bgImageUrl: '/assets/simon.jpg',
    },
    {
        id: 3,
        name: 'Semeon Mulatu (PhD)',
        status: 'General Secretary of  EKHC',
        bgImageUrl: '/assets/leader3.png',
    },
    {
        id: 4,
        name: 'Ermias Mamo (PhD)',
        status: 'Deputy General Secretary of  EKHC',
        bgImageUrl: '/assets/leader4.png',
    },
];
