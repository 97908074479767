import {
    Flex,
    Button,
    Text,
    Image,
    Box,
    Stack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import {Link} from 'react-router-dom';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {useState} from 'react';
import menu from './toggleMenu';
import {
    useButtonStyle,
    useWrapperFlexStyle,
    useImageStyle,
    useContentTextStyle,
} from './toggleSectionsStyle';
import {useMediaQuery} from '@chakra-ui/react';
import {ListComponent} from '../articles/Articles';
import colors from '../../constants/colors';

const MenuButtonCustom = ({
    item,
    currentId,
    setCurrentId,
    length,
    isMobile,
}) => {
    return (
        <Button
            key={item.id}
            {...useButtonStyle(currentId === item.id, isMobile)}
            onClick={() =>
                setCurrentId(
                    isMobile ? (item.id < length ? item.id + 1 : 1) : item.id
                )
            }
        >
            {item.title}
        </Button>
    );
};

const ToggleSection = () => {
    const [currentId, setCurrentId] = useState(1);

    const [isMobile] = useMediaQuery('(max-width: 425px)');

    return (
        <Box>
            {isMobile ? (
                <Stack direction={{base: 'column', md: 'row'}}>
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            {menu[currentId - 1].title}
                        </MenuButton>
                        <MenuList MenuList p={0} minW="0" w={'96vw'}>
                            {menu &&
                                menu.map(
                                    item =>
                                        item.id !== currentId && (
                                            <MenuItem
                                                onClick={() =>
                                                    setCurrentId(item.id)
                                                }
                                                w="auto"
                                                m="auto"
                                                key={item.id}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        )
                                )}
                        </MenuList>
                    </Menu>
                </Stack>
            ) : (
                <Stack direction={{base: 'column', md: 'row'}}>
                    {menu.map(item => {
                        return (
                            <MenuButtonCustom
                                item={item}
                                currentId={currentId}
                                setCurrentId={setCurrentId}
                                key={item.id}
                            />
                        );
                    })}
                </Stack>
            )}
            <Flex {...useWrapperFlexStyle()}>
                <Image src={menu[currentId - 1].image} {...useImageStyle()} />
                <Box>
                    <Text {...useContentTextStyle()} mb={8}>
                        <strong style={{marginBottom: '10px'}}>
                            {menu[currentId - 1].subtitle}
                        </strong>
                    </Text>
                    <Text {...useContentTextStyle()} noOfLines={10}>
                        {menu[currentId - 1].content}
                    </Text>
                    {menu[currentId - 1].list && (
                        <ListComponent list={menu[currentId - 1].list} />
                    )}
                    {menu[currentId - 1].large && (
                        <Box
                            color={colors.primary}
                            fontSize="20px"
                            textAlign={'left'}
                        >
                            <Link to="/about_us"> Read more </Link>
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default ToggleSection;
