import {
    Flex,
    VStack,
    Image,
    Box,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import useTypography from '../../constants/typography';
import { Link } from 'react-router-dom';
import {
    BoxContainer,
    BoxImageWrapper,
    ImageStyle,
    VStackStyle,
    FlexStyle,
} from './blogStyle';
import colors from '../../constants/colors';

const BlogArticl = ({
    image,
    title,
    content,
    type,
    id,
    setActiveBlogIndex,
    redirectBlog,
    toMainBlogSection,
}) => {
    return (
        <Flex {...BoxContainer(type)}>
            <Box {...BoxImageWrapper(type)}>
                <Image src={image?.src} {...ImageStyle} />
            </Box>
            <VStack {...VStackStyle(type)}>
                <Box>
                    <Text
                        {...useTypography(useBreakpointValue).blogTitle}
                        mb={6}
                        noOfLines={3}
                    >
                        {title}
                    </Text>
                    <Text
                        {...useTypography(useBreakpointValue).cardDescription}
                        noOfLines={4}
                    >
                        {content}
                    </Text>
                </Box>
                <Flex {...FlexStyle()}>
                    <Text
                        w="50%"
                        borderRight="1px solid"
                        color={colors.primary}
                        fontSize="20px"
                    >
                        {redirectBlog ? (
                            <Link to={`blog_details/${id}`}>Read more</Link>
                        ) : (
                            <Link
                                to={`#${toMainBlogSection}`}
                                onClick={() => setActiveBlogIndex(id)}
                            >
                                Read more
                            </Link>
                        )}
                    </Text>
                    <Text w="50%" ml={'10px'} my="auto">
                        2nd January, 2022
                    </Text>
                </Flex>
            </VStack>
        </Flex>
    );
};

export default BlogArticl;
