import colors from '../../constants/colors';

export const SimpleGridContainer = {
    minChildWidth: '200px',
    spacing: '20px',
    bg: colors.primary,
    px: 50,
};

export const StackStyle = {
    alignItems: 'center',
    justifyContent: 'flex-start',
    w: '100%',
    h: 300,
    px: 20,
    pt: '50px',
    bg: 'transparent',
    borderRadius: 30,
    color: 'white',
    transitionProperty: 'all',
};

export const BoxStyle = {
    w: '70px',
    h: '70px',
    bg: 'white',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
};
