const articlesMission = {
    normal: [
        {
            id: 1,
            image: {src: '/assets/kelemie_kana.JPG'},
            imageName: 'Dr. Kelemie Kana',
            imageTitle: 'Missions Department Head',
            title: 'The Ethiopian Kale Heywet Church (EKHC), Missions Department',
            content: `The Missions Department is one of the six departments 
            at the Ethiopian Kale Heywet Church Head Office under the Spiritual 
            Ministries Wing and it mainly has been focusing on preaching the good news both 
            in Ethiopia and beyond, winning souls for Christ, and planting new churches.
            There are nine different sections under this department and all are focusing in 
            one way or other on God’s number one agenda or top priority, which means Evangelism 
            or Mission.
            The following are the nine sections of Missions Department:`,
            listContents: [
                'National evangelists’ coordination section',
                'Global missionaries’ coordination section',
                'Gospel for other religions section',
                'Bible translation section',
                'Media evangelism and church planting section',
                'Hub school section',
                'Gospel Association section',
                'Church building and other related ministries section',
                'Firm Foundation in Christ ministries section ',
            ],
            additionalContent: `
              According to the EKHC five year strategic plan, the number of churches
               and members will be doubled when the church celebrates her centennial or 
               one hundred years’ anniversary and it is obvious that the Missions Department 
               will play a very significant role in fulfilling this mighty vision.
              Finally, I would ask the saints to be with this department in their prayers.`,
            contactsTitle: `Contacts of the Missions Department`,
            contacts: [
                'Mobile: +251-9 11 30 41 40',
                'Office: +251-11 550 5898',
                'Fax:  +251-11 551 2763',
                'E-mail:  kelemeelfu@gmail.com',
            ],
            imagePos: 'left',
            textWrap: 602,
        },
        {
            id: 2,
            image: {src: '/assets/mission2.jpg'},
            content: `The Church had played pivotal role in transforming the life of people trough 
              sustainable evangelism though then socialist government had confiscated its properties and
               the members were doomed to tempting hardship. After the fall of the dergue regime, the 
               Church has gained ground by further expansion of its holistic ministry and could excel 
               to this extent successfully.
      
              Currently, The Ethiopian Kale Heywet Church is one of the giant Main Line Churches with 
              above 9 Million members, around 10,000 Local Churches which are structured under 11 Zonal 
              (ketena) administrations, 105 fellowships which are distributed throughout the Country. 
              It has also 9 special centers that provide wide range of service. The Church possesses 
              1,100 global missionaries beside those who serve at Local Church level. We have maneuvered 
              9 theological schools, 145 Bible Schools and thousands other academic schools to serve our 
              ministers and the whole nation at large.
              
              The Church has also engaged in various charity, sympathy and Christian love ministries by 
              supporting old people, widows, Orphans and other sections of the society that need support 
              in stewardship mentality. By doing so, it is contributing its part in the development of 
              the nation.`,
            imagePos: 'right',
            textWrap: 800,
        },
    ],
};

export const articlesStrategy = {
    normal: [
        {
            id: 1,
            image: {src: '/assets/strategy_img.jpg'},
            title: 'Our Strategy',
            content: `The Ethiopian Kale Heywet Church Missions department has two wings: global missions 
              and local missions. Global missions is an office where we mobilize and train churches for 
              global missions and commissioning missionaries when they are ready to go. So far 17 couple 
              missionaries are working in 11 different countries.
      
              Local missions is an office where we coordinate six different sections: evangelism, church 
              planting, Media evangelism, Training center for church planters (TCCP, different from church
              planting section), Gospel to other religions and Hub schools (mobile mission schools). 
              Through evangelism so far 1,094 missionaries are sent to different part of the country and 
              working among unreached people groups.
              
              What makes our evangelism both globally and locally is that all are supported by local
              churches. Ethiopians are poor people but their commitment, passion for the gospel and 
              sacrificial gift made this to happen. We thank God for what He is doing through our churches.`,
            imagePos: 'right',
            textWrap: 700,
        },
    ],
};

export default articlesMission;
