import {Box} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import {articlesTheology} from '../components/articles/articlesTheology';
import {useMediaQuery} from '@chakra-ui/react';
import PersonCards from '../components/person_cards/PersonCards';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import TheologyArticles from '../components/articles/educationDep/TheologyArticles';

const TheologicalAndMinisterialEducation = () => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');
    const articlesTheologyMobile = {
        normal: articlesTheology.normal.map(article => ({
            ...article,
            imagePos: 'right',
        })),
        subArticles: articlesTheology.subArticles.map(article => ({
            ...article,
            imagePos: 'right',
        })),
    };

    return (
        <>
            <ScrollToTop />
            <Box h={'400px'} overflowY="hidden">
                <HeroWithTitleCenter
                    title="Theological And Ministerial Education"
                    bgUrl="url('/assets/worship.jpeg')"
                />
            </Box>
            <Box
                px={{base: '10%', md: '8%'}}
                py={{base: 20, md: 40}}
                mt={{base: 20}}
            >
                <TheologyArticles
                    articles={
                        isMobile ? articlesTheologyMobile : articlesTheology
                    }
                    withPlain={true}
                    wrapped={!isMobile && true}
                />
                <PersonCards
                    persons={articlesTheology.memberPeoples}
                    title="Department Leaders"
                />
            </Box>
        </>
    );
};

export default TheologicalAndMinisterialEducation;
