import {useBreakpointValue} from '@chakra-ui/react';
import useTypography from '../../constants/typography';

export const useButtonStyle = (bool, isMobile) => ({
    borderRadius: '1rem 1rem 0 0',
    px: 10,
    py: useBreakpointValue({base: 4, md: 8}),
    bg: isMobile
        ? '#e2e8f0'
        : bool
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(255, 255, 255, 0.48)',
    opacity: bool && 1,
    w: {sm: '50px', md: '150px'},
});

export const useWrapperFlexStyle = () => ({
    flexDirection: useBreakpointValue({base: 'column', lg: 'row'}),
    bg: 'white',
    alignItems: 'center',
    px: useBreakpointValue({base: 4, md: 10}),
    py: 20,
    borderRadius: '0 20px 0 0',
    boxShadow: '5px 10px 30px #eef2fb',
});

export const useImageStyle = () => ({
    h: '115px',
    w: '204px',
    mr: useBreakpointValue({base: 0, lg: '30px'}),
    mb: useBreakpointValue({base: '30px', lg: 0}),
});

export const useContentTextStyle = () => ({
    textAlign: useBreakpointValue({base: 'center', lg: 'left'}),
    ...useTypography(useBreakpointValue).articleContent,
});
