import {useBreakpointValue} from '@chakra-ui/react';
import useTypography from '../../constants/typography';
import colors from '../../constants/colors';

export const useTitleStyle = () => ({
    mb: 20,
    color: colors.primary,
    ...useTypography(useBreakpointValue).sectionTitle,
});

export const BoxStyle = {
    h: 80,
    w: '100%',
    bg: 'gray.300',
    mb: 8,
    borderRadius: '8px',
};

export const SimpleGridStyle = {
    minChildWidth: '240px',
    spacing: '60px',
};
