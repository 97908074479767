import {Box, Spinner} from '@chakra-ui/react';
import {useParams} from 'react-router-dom';
import {ArticleWithImage} from '../components/articles/Articles';
import {ArticleWithImageWrapped} from '../components/articles/Articles';
import {useMediaQuery} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
import {db} from '../firebase/firebaseConfig';
import {doc, getDoc} from 'firebase/firestore';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const BlogDetails = () => {
    const {id} = useParams();
    const [isMobile] = useMediaQuery('(max-width: 425px)');

    const [blog, setBlog] = useState({});

    useEffect(() => {
        const getBlog = async () => {
            const blogRef = await doc(db, 'blogs', id);

            try {
                const blogSnap = await getDoc(blogRef);
                setBlog({
                    ...blogSnap.data(),
                    image: {src: blogSnap.data().image},
                    id: id,
                });
            } catch (error) {
                console.log(error);
            }
        };

        getBlog();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ScrollToTop />
            <Box
                px={{base: '10%', md: '8%'}}
                py={{base: 0, md: 40}}
                pt={{base: 20, md: 40}}
            >
                {blog?.title ? (
                    isMobile ? (
                        <ArticleWithImage article={blog} index={0} />
                    ) : (
                        <ArticleWithImageWrapped article={blog} index={0} />
                    )
                ) : (
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                )}
            </Box>
        </>
    );
};

export default BlogDetails;
