import useTypography from '../../constants/typography';
import {useBreakpointValue} from '@chakra-ui/react';

export const useArticleTitleStyle = () => ({
    ...useTypography(useBreakpointValue).articleTitle,
    w: '100%',
});
export const useGridContainerStyle = () => ({
    templateColumns: useBreakpointValue({md: '3fr 9fr'}),
    gap: 0,
    alignItems: 'center',
});
