export const photos = [
    {id: 1, src: '/assets/mission_photo1.png'},
    {id: 2, src: '/assets/mission_photo2.png'},
    {id: 3, src: '/assets/mission_photo3.png'},
    {id: 4, src: '/assets/mission_photo4.png'},
    {id: 5, src: '/assets/mission_photo5.png'},
    {id: 6, src: '/assets/mission_photo6.png'},
    {id: 7, src: '/assets/mission_photo7.png'},
    {id: 8, src: '/assets/mission_photo8.png'},
];
