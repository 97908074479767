const useTypography = useBreakpointValue => ({
    mainNavItems: {
        fontSize: useBreakpointValue({md: '16px', lg: '22px'}),
        lineHeight: '1.5rem',
    },
    heroSubTitle: {
        fontSize: useBreakpointValue({base: '2xl', md: '3xl', lg: '4xl'}),
        fontWeight: 100,
        lineHeight: 1.2,
    },
    heroTitle: {
        fontWeight: 700,
        lineHeight: 1.2,
        fontSize: useBreakpointValue({base: '3xl', md: '4xl', lg: '6xl'}),
    },
    sectionTitle: {
        fontWeight: 700,
        lineHeight: 1.2,
        fontSize: useBreakpointValue({base: '2xl', md: '3xl', lg: '5xl'}),
    },
    hTiny: {
        lineHeight: '1.5em',
        fontSize: useBreakpointValue({base: 'sm', md: '2sm', lg: '4sm'}),
    },
    cardTitle: {
        marginTop: '10px',
        marginBottom: '10px',
        fontFamily: "'Inter',sans-serif",
        fontSize: useBreakpointValue({base: '22px', md: '1.3em'}),
        lineHeight: useBreakpointValue({base: '28px', md: '1.3em'}),
        fontWeight: 600,
    },
    cardTitleMd: {
        marginTop: '10px',
        marginBottom: '10px',
        fontFamily: "'Inter',sans-serif",
        fontSize: useBreakpointValue({base: '22px', md: '1.5em'}),
        lineHeight: useBreakpointValue({base: '28px', md: '1.5em'}),
        fontWeight: 600,
    },
    cardDescription: {
        fontFamily: "'Inter',sans-serif",
        fontSize: useBreakpointValue({base: '16px', md: '1.2em'}),
        lineHeight: '1.5em',
        fontWeight: '400',
    },
    cardPersonName: {
        fontWeight: 700,
        lineHeight: 1.2,
        fontSize: useBreakpointValue({base: '24px', lg: '26px'}),
    },
    blogTitle: {
        marginTop: '10px',
        marginBottom: '10px',
        fontFamily: "'Inter',sans-serif",
        fontSize: useBreakpointValue({base: '22px', md: '1.3em'}),
        lineHeight: useBreakpointValue({base: '28px', md: '1.3em'}),
        fontWeight: 600,
    },
    chip: {
        fontWeight: 500,
        lineHeight: 1.2,
        fontSize: '14px',
    },
    articleTitle: {
        marginBottom: '20px',
        fontFamily: "'Inter',sans-serif",
        color: '#191b1d',
        fontSize: '36px',
        lineHeight: '48px',
        fontWeight: 700,
        transform:
            'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        opacity: 1,
        paddingBlockStart: 0,
    },
    articleContent: {
        fontFamily: "'Inter',sans-serif",
        color: '#1e1e1e',
        fontSize: useBreakpointValue({base: '18px', md: '22.4px'}),
        lineHeight: useBreakpointValue({base: '26px', md: '1.5em'}),
        fontWeight: 400,
    },
});

export default useTypography;
