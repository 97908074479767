import {useBreakpointValue} from '@chakra-ui/react';
import useTypography from '../../constants/typography';

export const BoxContainer = (type = 'column') => ({
    w: '100%',
    borderRadius: '30px',
    overflow: 'hidden',
    direction: type === 'row' ? 'row' : 'column',
});

export const BoxImageWrapper = (type = 'column') => ({
    w: type === 'row' && '50%',
    h: type === 'row' ? 320 : 150,
    overflow: 'hidden',
});

export const ImageStyle = {
    w: '100%',
    objectFit: 'cover',
    h: '100%',
};

export const VStackStyle = (type = 'column') => ({
    bg: '#F2F6FF',
    px: 6,
    py: 8,
    h: '320px',
    textAlign: 'left',
    justifyContent: 'space-between',
    width: type === 'row' && '50%',
});

export const FlexStyle = () => ({
    justifyContent: 'space-between',
    w: '100%',
    ...useTypography(useBreakpointValue).chip,
});
