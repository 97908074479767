import WithBackgroundImage from '../components/hero/Hero';
import {Grid, Box} from '@chakra-ui/react';
import ToggleSection from '../components/toggle_sections/ToggleSections';
import HighlightCard from '../components/highlight_card/HighlightCard';
import CardStack from '../components/card_stack/CardStack';
import PersonCards from '../components/person_cards/PersonCards';
import {persons} from '../components/person_cards/personCardsData';
import Accordions from '../components/accordions/Accordions';
import ArticleList from '../components/blog_articles/BlogArticles';
import LinkBar from '../components/link_bar/LinkBar';
import {useState, useEffect} from 'react';
import {db} from '../firebase/firebaseConfig';
import {collection, getDocs} from 'firebase/firestore';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const Home = () => {
    const [blogsNews, setBlogsNews] = useState([]);
    const blogsCollectionRef = collection(db, 'blogs');

    useEffect(() => {
        const getBlogs = async () => {
            const data = await getDocs(blogsCollectionRef);
            setBlogsNews(
                data.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    image: {src: doc.data().image},
                }))
            );
        };

        getBlogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    blogsNews.sort((a, b) => b.createdAt - a.createdAt);

    const firstFourBlogs = blogsNews.slice(0, 4);

    console.log({firstFourBlogs});
    return (
        <>
            <ScrollToTop />
            <Grid width={'100%'}>
                <WithBackgroundImage />
                <Box
                    mt={{base: 20, md: '-100px', lg: -40}}
                    px={{base: '2%', md: '8%'}}
                    mx="auto"
                    h={'auto'}
                    w={'100%'}
                >
                    <ToggleSection />
                </Box>
                <Box
                    width={'100%'}
                    px={{md: 10}}
                    py={40}
                >
                    <HighlightCard />
                </Box>
                <Box>
                    <CardStack />
                </Box>
                <Box width={'100%'} px={{base: '10%', md: '8%'}} py={40}>
                    <Accordions />
                </Box>
                <Box px={{base: '10%', md: '8%'}} mb={40}>
                    <PersonCards persons={persons} title="Our Leaders" />
                </Box>

                <Box px={{base: '10%', md: '8%'}} mb={40}>
                    <ArticleList
                        title="Latest News From Our Blogs"
                        blogs={firstFourBlogs}
                        redirectBlog={true}
                    />
                </Box>

                <Box width="100%" mb={40}>
                    <LinkBar />
                </Box>
            </Grid>
        </>
    );
};

export default Home;
