import {Grid, Box} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import {ArticleWithListAndImage} from '../components/articles/Articles';
import {articlesLiterature} from '../components/articles/articlesLiterature';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const LiteratureAndCommunication = () => {
    return (
        <>
            <ScrollToTop />
            <Grid>
                <Box h={'400px'} overflowY="hidden">
                    <HeroWithTitleCenter
                        title="Literature & Communication"
                        bgUrl="url('/assets/worship.jpeg')"
                    />
                </Box>
                <Box px={{base: '10%', md: '8%'}} py={20} mt={20}>
                    <ArticleWithListAndImage
                        article={articlesLiterature.normal[0]}
                    />
                </Box>
            </Grid>
        </>
    );
};

export default LiteratureAndCommunication;
