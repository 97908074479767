import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

function SubmitModal({isOpen, onClose, modalText}) {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>{modalText}</ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default SubmitModal;
