import colors from '../../constants/colors';
import useTypography from '../../constants/typography';
import {useBreakpointValue} from '@chakra-ui/react';

export const FlexContainer = bgUrl => ({
    w: 'full',
    h: 'full',
    backgroundImage: bgUrl || "url('/assets/worship.jpeg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
});
export const useVStackStyle = useBreakpointValue => ({
    w: 'full',
    justify: 'center',
    px: useBreakpointValue({base: 4, md: 32}),
    align: 'center',
    bgGradient: `linear(to-r, ${colors.primary} 0%, ${colors.primary} 25%, transparent)`,
});

export const useHeroTitle = () => ({
    color: 'white',
    textAlign: 'center',
    ...useTypography(useBreakpointValue).heroTitle,
});

export const wrapperStackStyle = {
    maxW: '3xl',
    align: 'flex-start',
    justifyContent: 'left',
};
