const colors = {
    primary: '#1e4bc0',
    primaryLight: '#1f4dc1',
    blueWhite: '#f6f8fc',
    articleTitle: '#191b1d',
    articleContent: '#1e1e1e',
    inputBg: '#f2f6ff',
};

export default colors;
