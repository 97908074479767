const ministries = [
    {
        id: 1,
        title: 'National and Global Mission Ministry',
        list: [
            {id: 1, text: 'Coordinating Gospel Ministry and Missionaries'},
            {id: 2, text: 'Coordinating Global Ministry and Missionaries'},
            {id: 3, text: 'Evangelizing different areas and denominations.'},
            {id: 4, text: 'Disseminating Gospel using mass media.'},
            {
                id: 5,
                text: 'Coordinating Bible translation and helping people to receive Jesus by reading the Gospel.',
            },
            {
                id: 6,
                text: 'Motivating believers to lend their hands through ministering, financial and other means.',
            },
            {id: 7, text: 'Training evangelists and Church planters.'},
            {
                id: 8,
                text: 'Planting Local Churches with their respective building side by side',
            },
        ],
    },
    {
        id: 2,
        title: 'Church Teaching and Descipleship Ministry',
        list: [
            {
                id: 1,
                text: 'Preparing disciples for Christ by teaching fundamentals of Christianity.',
            },
            {
                id: 2,
                text: 'Providing healthy Christian education in Sunday Schools.',
            },
            {id: 3, text: 'Training Church leaders and pastors.'},
            {
                id: 4,
                text: 'Providing training on healthy marriage and family life.',
            },
            {
                id: 5,
                text: 'Edifying members through Biblical worship and motivating participation.',
            },
        ],
    },
    {
        id: 3,
        title: 'Theological Ministry',
        list: [
            {
                id: 1,
                text: 'Training Ministers in the 9 Kale Heywet’s theological colleges at Diploma and Degree level.',
            },
            {
                id: 2,
                text: 'Training Ministers in the 145 Bible Schools at certificate and diploma level.',
            },
            {
                id: 3,
                text: 'Training Ministers in informal theological centers.',
            },
            {
                id: 4,
                text: 'Training leaders at higher structure in partnership with Antiok (Antsokia) post graduate institution.',
            },
        ],
    },
    {
        id: 4,
        title: 'Youth Ministry',
        list: [
            {id: 1, text: 'Teaching Youth Ministry leaders.'},
            {
                id: 2,
                text: 'Providing timely training to the Youth in various dimensions.',
            },
            {
                id: 3,
                text: 'Providing Sport Ministry to the Youth’s healthy life.',
            },
            {
                id: 4,
                text: 'Serving the Youth to enable them academically in Holistic way.',
            },
        ],
    },
    {
        id: 5,
        title: "Women's Ministry",
        list: [
            {id: 1, text: 'Tanning women leaders.'},
            {
                id: 2,
                text: 'Training Women in the Church’s various centers to make them skillful.',
            },
            {
                id: 3,
                text: 'Training Women in the way they can influence family and community.',
            },
        ],
    },
    {
        id: 6,
        title: 'Children Ministry',
        list: [
            {
                id: 1,
                text: 'Ministry of coordinating both children’s Sunday school and academic schools.',
            },
            {id: 2, text: 'Ministry of publishing books for children.'},
            {id: 3, text: 'Children’s charity ministry.'},
            {id: 4, text: 'Training ministers for children ministry.'},
        ],
    },
];

export default ministries;
