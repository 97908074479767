// import { ReactNode } from 'react';
import {
    Box,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';
import {NavLink as ReactDomLink} from 'react-router-dom';
import {HamburgerIcon, CloseIcon} from '@chakra-ui/icons';
import OnHoverMenu from '../on_hover_menu/OnHoverMenu';
import {
    useLinkStyle,
    useBoxContainer,
    FlexWrapper,
    IconButtonStyle,
    LinksStackContainer,
    LinksStackWrapper,
    MobileBoxContainer,
    MobileLinksStackWrapper,
} from './headerStyle';

const Links = [
    {id: 1, text: 'Home', path: '/'},
    {id: 2, text: 'Ministries', path: '/ministries'},
    {id: 3, text: 'News & Events', path: '/news_and_events'},
    {id: 4, text: 'About Us', path: '/about_us'},
    {id: 5, text: 'Contact Us', path: '/contact_us'},
];

const MobileLinks = [
    {id: 1, text: 'Home', path: '/'},
    {
        id: 2,
        text: 'Literature and Communications',
        path: '/literature_and_communication',
    },
    {id: 3, text: 'Mission Department', path: '/mission_department'},
    {
        id: 4,
        text: 'Theological And Ministerial Education',
        path: '/theological_and_ministerial_education',
    },
    {
        id: 5,
        text: 'Discipleship and Church Education Department',
        path: '/discipleship_and_church_education_department',
    },
    {id: 6, text: 'News & Events', path: '/news_and_events'},
    {id: 7, text: 'About Us', path: '/about_us'},
    {id: 8, text: 'Contact Us', path: '/contact_us'},
];

const NavLink = ({children, path}) => (
    <ReactDomLink to={path}>
        <Box {...useLinkStyle(useColorModeValue, useBreakpointValue)}>
            {children}
        </Box>
    </ReactDomLink>
);

const menuItems = [
    {
        id: 1,
        Comp: (
            <NavLink path="/literature_and_communication">
                Literature & Communication
            </NavLink>
        ),
    },
    {
        id: 2,
        Comp: <NavLink path="/mission_department">Mission Department</NavLink>,
    },
    {
        id: 3,
        Comp: (
            <NavLink path="/theological_and_ministerial_education">
                Theological And Ministerial Education
            </NavLink>
        ),
    },
    {
        id: 4,
        Comp: (
            <NavLink path="/discipleship_and_church_education_department">
                Discipleship and Church Education Department
            </NavLink>
        ),
    },
];

export default function Simple() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            <Box {...useBoxContainer(useColorModeValue, useBreakpointValue)}>
                <Flex {...FlexWrapper}>
                    <IconButton
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        onClick={isOpen ? onClose : onOpen}
                        {...IconButtonStyle}
                    />
                    <HStack {...LinksStackContainer}>
                        <HStack w={{base: '60', mds: '80'}}>
                            <ReactDomLink to="/">
                                <Image src="/assets/new-ekhc-web-banner.png" />
                            </ReactDomLink>
                        </HStack>
                        <HStack {...LinksStackWrapper}>
                            {Links.map(link =>
                                link.path === '/ministries' ? (
                                    <OnHoverMenu
                                        menuItems={menuItems}
                                        key={link.id}
                                    >
                                        <NavLink path={link.path}>
                                            {link.text}
                                        </NavLink>
                                    </OnHoverMenu>
                                ) : (
                                    <NavLink key={link.id} path={link.path}>
                                        {link.text}
                                    </NavLink>
                                )
                            )}
                        </HStack>
                    </HStack>
                </Flex>

                {isOpen ? (
                    <Box {...MobileBoxContainer}>
                        <Stack {...MobileLinksStackWrapper}>
                            {MobileLinks.map(link => (
                                <Box onClick={onClose}>
                                    <NavLink key={link.id} path={link.path}>
                                        {link.text}
                                    </NavLink>
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    );
}
