import useTypography from '../../constants/typography';
import {useBreakpointValue, useColorModeValue} from '@chakra-ui/react';
import colors from '../../constants/colors';

export const VStackStyle = args => ({
    textAlign: 'left',
    alignItems: 'flex-start',
    ...args,
});

export const useArticleTitleStyle = color => ({
    ...useTypography(useBreakpointValue).articleTitle,
    color: color && color,
    w: '100%',
});

export const useArticleContentStyle = color => ({
    ...useTypography(useBreakpointValue).articleContent,
    color: color && color,
});

export const ImageWrapperStyle = {
    pos: 'relative',
    h: 'fit-content',
};

export const TopLeftDecorBoxStyle = {
    pos: 'absolute',
    left: '-20px',
    top: '-35px',
    w: '60px',
    h: '70px',
    bg: colors.primary,
    zIndex: -5,
};

export const TopRightDecorBoxStyle = {
    pos: 'absolute',
    right: '-30px',
    top: '-35px',
    w: '60px',
    h: '70px',
    bg: colors.primary,
    zIndex: -5,
};

export const BottomRightDecorBoxStyle = {
    pos: 'absolute',
    right: '10px',
    bottom: '-40px',
    w: '60px',
    h: '70px',
    bg: colors.primary,
    zIndex: -5,
};

export const BottomLeftDecorBoxStyle = {
    pos: 'absolute',
    left: '-30px',
    bottom: '30px',
    w: '60px',
    h: '70px',
    bg: colors.primary,
    zIndex: -5,
};

export const ArticleImageStyle = {
    w: '100%',
    objectFit: 'cover',
};

export const useArticleWIthImageContainer = () => ({
    templateColumns: useBreakpointValue({lg: '1fr 1fr'}),
    gap: 14,
    mb: 40,
});

export const useArticleWIthImageWrappedContainer = () => ({
    display: 'block',
});

export const usePlainArticleContainer = () => ({
    templateColumns: useBreakpointValue({lg: '1fr 1fr'}),
    gap: 6,
});

export const PlainArticleArgs = {
    args: {
        p: '20% 10% 10% 10%',
        bg: colors.primary,
        color: 'white',
    },
};

export const useLinkStyle = () => ({
    px: 2,
    py: 1,
    rounded: 'md',
    _hover: {
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
    },
    color: colors.primaryLight,
    ...useTypography(useBreakpointValue).mainNavItems,
});
