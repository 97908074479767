export const contactUsTemplate = formData => `
    <h1 style="background-color: #007bff; height: 100px; text-align: center; line-height: 100px; color: #fff;">Request for Contact</h1>
    <div style="display:flex;flex-direction:row;justify-content:center;align-items:center">
    	<p style="text-left: center; margin-top: 20px; margin-bottom: 20px; color: #333333; padding-left:30px;padding-right:30px;">
	      Hello,<br><br>
	      ${formData.firstName} ${formData.lastName} would like to request contact with the below details:<br><br>
	      Name: ${formData.firstName} ${formData.lastName}<br>
	      Email: ${formData.email}<br>
		  Message: ${formData.message}<br><br>
	      he/she look forward to hearing back from you soon.<br><br>
	      <br>
  	    </p>
    </div>
`;
