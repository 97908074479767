import {useBreakpointValue} from '@chakra-ui/react';
import colors from '../../constants/colors';
import useTypography from '../../constants/typography';

export const useTitleStyle = () => ({
    mb: 20,
    color: colors.primary,
    ...useTypography(useBreakpointValue).sectionTitle,
});

export const useGridContainer = () => ({
    templateColumns: useBreakpointValue({md: '4fr 3fr'}),
    gap: 14,
    color: 'white',
});

export const BoxContainer = {
    background: colors.primary,
};

export const FormInput = () => ({
    background: 'white',
    size: useBreakpointValue({base: 'sm', lg: 'lg'}),
    height: 14,
});

export const TextAreaInput = () => ({
    background: 'white',
    placeholder: 'Write your message here',
    size: useBreakpointValue({base: 'sm', lg: 'lg'}),
    rows: 9,
});
