import useTypography from '../../constants/typography';
import {useBreakpointValue} from '@chakra-ui/react';

export const useArticleTitleStyle = () => ({
    ...useTypography(useBreakpointValue).articleTitle,
    textAlign: 'left',
    w: '100%',
});

export const useArticleWIthImageContainer = () => ({
    templateColumns: useBreakpointValue({lg: '1fr 1fr'}),
    gap: 0,
    alignItems: 'center',
});
