import {
    Text,
    SimpleGrid,
    Image,
    Grid,
    GridItem,
    Box,
} from '@chakra-ui/react';
import {useArticleTitleStyle, useGridContainerStyle} from './photoSeriesStyle';
import {photos} from './photoSeriesData';

const PhotoSeries = () => {
    return (
        <Grid {...useGridContainerStyle()}>
            <GridItem textAlign={'left'}>
                <Text {...useArticleTitleStyle()}>
                    Mission Dept. Photo Series
                </Text>
            </GridItem>
            <GridItem>
                <SimpleGrid minChildWidth={'225px'} spacing={8}>
                    {photos.map(item => {
                        return (
                            <Box h={40} key={item.id}>
                                <Image
                                    src={item.src}
                                    borderRadius="6px"
                                    objectFit={'cover'}
                                    boxSize={'100%'}
                                />
                            </Box>
                        );
                    })}
                </SimpleGrid>
            </GridItem>
        </Grid>
    );
};

export default PhotoSeries;
