import {
    FormControl,
    FormLabel,
    Box,
    Input,
    Textarea,
    VStack,
    Grid,
    GridItem,
    Text,
    Flex,
    Button,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import colors from '../../constants/colors';
import {
    useGridContainer,
    BoxContainer,
    FormInput,
    TextAreaInput,
} from './ContactFormStyle';
import useTypography from '../../constants/typography';
import {db} from '../../firebase/firebaseConfig';
import {addDoc, collection} from 'firebase/firestore';
import SubmitModal from '../SubmitModal/SubmitModal';
import {getFormattedCurrentDate} from '../../util/utils';
import {contactUsTemplate} from '../email_template/ContactUsTemplate';
import {enqueueSnackbar} from 'notistack';

const ContactForm = () => {
    const {register, reset, handleSubmit, setValue, watch} = useForm();

    const modalText = {
        success: 'Submitted, Thanks for getting in touch with us.',
        failure: 'Your message is not submitted. Please try again!',
    };
    const [modalOpen, setModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const contactsCollectionRef = collection(db, 'contacts');
    const mailCollectionRef = collection(db, 'mail');

    const handleFormSubmit = async formData => {
        try {
            const createdAt = getFormattedCurrentDate();

            await addDoc(contactsCollectionRef, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                message: formData.message,
                createdAt: createdAt,
            });

            enqueueSnackbar('Submitted Successfully', {
                variant: 'success',
            });

            await addDoc(mailCollectionRef, {
                to: ['ekhc.net@gmail.com'],
                message: {
                    subject: 'Client requesting contact',
                    html: contactUsTemplate(formData),
                },
            });

            reset();
            setValue('message', '');
        } catch (e) {
            console.error('Error', e);
            setErrorModalOpen(true);
        }
    };

    return (
        <Box {...BoxContainer}>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="form">
                <input
                    type="hidden"
                    name="accessKey"
                    value="b358ee74-273d-4a5f-b70f-309c5b7071c4"
                />
                <VStack gap={4}>
                    <Flex justifyContent={'space-between'} w="100%">
                        <FormControl isRequired w="48%">
                            <FormLabel>First name</FormLabel>
                            <Input
                                color={'black'}
                                {...register('firstName', {required: true})}
                                name="firstName"
                                defaultValue={watch('firstName')}
                                {...FormInput()}
                            />
                        </FormControl>
                        <FormControl isRequired w="48%">
                            <FormLabel>Last name</FormLabel>
                            <Input
                                color={'black'}
                                {...register('lastName', {required: true})}
                                name="lastName"
                                defaultValue={watch('lastName')}
                                {...FormInput()}
                            />
                        </FormControl>
                    </Flex>
                    <FormControl isRequired>
                        <FormLabel>Email address</FormLabel>
                        <Input
                            color={'black'}
                            type="email"
                            name="email"
                            defaultValue={watch('email')}
                            {...register('email', {required: true})}
                            {...FormInput()}
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Message</FormLabel>
                        <Textarea
                            color={'black'}
                            {...register('message', {required: true})}
                            {...TextAreaInput()}
                            name="message"
                            value={watch('message')}
                        />
                    </FormControl>
                    <Stack w="100%">
                        <Button
                            type="submit"
                            background="white"
                            color={colors.primary}
                            mt={12}
                            ml="auto"
                            mr={0}
                            size="lg"
                        >
                            Submit
                        </Button>
                    </Stack>
                </VStack>
            </form>
            <SubmitModal
                isOpen={modalOpen}
                modalText={modalText.success}
                onClose={() => setModalOpen(false)}
            />
            <SubmitModal
                isOpen={errorModalOpen}
                modalText={modalText.failure}
                onClose={() => setErrorModalOpen(false)}
            />
        </Box>
    );
};

const emailList = [];

const EmailList = ({response}) => {
    return (
        <VStack alignItems="left" gap={5} mt={12}>
            {emailList.map(item => {
                const {key, title, email} = item;
                return (
                    <Text
                        key={key}
                        textAlign="left"
                    >{`${title}: ${email}`}</Text>
                );
            })}
            <Box>
                {response.type}: {response.message}
            </Box>
        </VStack>
    );
};

const FormSection = () => {
    const [response, setResponse] = useState({
        type: '',
        message: '',
    });
    return (
        <Box>
            <VStack textAlign={'left'} mb={16}>
                <Text
                    {...useTypography(useBreakpointValue).heroSubTitle}
                    w="100%"
                >
                    Contact Us
                </Text>
                <Text w="100%">
                    Leave us your a message below and we will get back to you as
                    soon as possible.
                </Text>
            </VStack>
            <Grid {...useGridContainer()}>
                <GridItem>
                    <ContactForm
                        response={response}
                        setResponse={setResponse}
                    />
                </GridItem>
                <GridItem>
                    <EmailList response={response} />
                </GridItem>
            </Grid>
        </Box>
    );
};

export default FormSection;
