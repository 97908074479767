import {
    Stack,
    Box,
    Text,
    SimpleGrid,
    useBreakpointValue,
    Image,
} from '@chakra-ui/react';
import useTypography from '../../constants/typography';
import {useTitleStyle, BoxStyle, SimpleGridStyle} from './personCardsStyles';

const Card = ({person}) => {
    return (
        <Box w="100%">
            <Stack alignItems={'flex-start'}>
                {person.bgImageUrl && (
                    <Box {...BoxStyle}>
                        <Image
                            objectPosition={'top'}
                            src={person.bgImageUrl}
                            objectFit="cover"
                            boxSize={'100%'}
                        />
                    </Box>
                )}
                <Text {...useTypography(useBreakpointValue).cardPersonName}>
                    {person.name}
                </Text>
                <Text
                    {...useTypography(useBreakpointValue).cardDescription}
                    textAlign="left"
                    fontWeight={500}
                >
                    {person.status}
                </Text>
                {person?.contacts?.map(person => (
                    <Text
                        style={{
                            fontSize: '14px',
                        }}
                    >
                        {person}
                    </Text>
                ))}
            </Stack>
        </Box>
    );
};

const PersonCards = ({persons, title}) => {
    return (
        <Stack>
            <Text {...useTitleStyle()}>{title}</Text>
            <SimpleGrid {...SimpleGridStyle}>
                {persons.map(person => {
                    return <Card person={person} key={person.id} />;
                })}
            </SimpleGrid>
        </Stack>
    );
};

export default PersonCards;
