import {Stack, Flex, Text, VStack, useBreakpointValue} from '@chakra-ui/react';
import {
    FlexContainer,
    useVStackStyle,
    useHeroTitle,
    wrapperStackStyle,
} from './hero2Style';

export default function HeroWithTitleCenter({title, bgUrl}) {
    return (
        <Flex {...FlexContainer(bgUrl)}>
            <VStack {...useVStackStyle(useBreakpointValue)}>
                <Stack {...wrapperStackStyle}>
                    <Text {...useHeroTitle()}>{title}</Text>
                </Stack>
            </VStack>
        </Flex>
    );
}
