import {Box} from '@chakra-ui/react';
import HeroWithTitleCenter from '../components/hero2/Hero2';
import Articles from '../components/articles/Articles';
import articlesData from '../components/articles/articlesData';
import {useMediaQuery} from '@chakra-ui/react';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const AboutUs = () => {
    const [isMobile] = useMediaQuery('(max-width: 425px)');
    const articlesDataMobile = {
        normal: articlesData.normal.map(article => ({
            ...article,
            imagePos: 'left',
        })),
    };

    return (
        <>
            <ScrollToTop />
            <Box h={'400px'} overflowY="hidden">
                <HeroWithTitleCenter
                    title="About Us"
                    bgUrl="url('/assets/worship.jpeg')"
                />
            </Box>
            <Box
                px={{base: '10%', md: '8%'}}
                py={{base: 20, md: 40}}
                mt={{base: 20}}
            >
                <Articles
                    articles={isMobile ? articlesDataMobile : articlesData}
                    withPlain={true}
                    wrapped={!isMobile && true}
                />
            </Box>
        </>
    );
};

export default AboutUs;
